/* Modern authentication styles */
.modern-auth-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

/* Left side - Logo Area (white) */
.logo-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  color: #000;
  position: relative;
}

.logo-content {
  position: relative;
  z-index: 1;
  max-width: 480px;
  padding: 0 1rem;
}

.welcome-heading {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #000;
}

.welcome-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  text-align: center;
  color: #333;
}

.divider-line {
  width: 60px;
  height: 4px;
  background-color: rgba(149, 16, 30, 0.5);
  border-radius: 2px;
  margin: 0 auto;
}

/* Right side - Form Area (red) */
.form-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background: linear-gradient(135deg, #95101e 0%, #760c18 100%);
  color: #fff;
  position: relative;
  overflow: hidden;
}

.form-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
  opacity: 0.3;
}

.form-container {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  position: relative;
  z-index: 1;
}

/* Form title styles (for the red background) */
.form-area .ant-typography {
  color: #fff !important;
}

.form-area p {
  color: rgba(255, 255, 255, 0.8) !important;
}

.form-area label {
  color: rgba(255, 255, 255, 0.9) !important;
}

/* Logo styles */
.logo-wrapper {
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
}

.logo-container {
  width: 350px;
  height: 180px;
  margin: 0 auto 1.5rem;
  background-image: url('../../public/images/advisorlogo.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* Custom form styles to match color scheme */
.ant-input, .ant-input-password {
  background-color: rgba(255, 255, 255, 0.9) !important;
  transition: all 0.3s;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #95101e !important;
}

/* You might also want to update the tab ink bar color to match */
.ant-tabs-ink-bar {
  background-color: #95101e !important;
}

.ant-btn-primary {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #95101e !important;
  font-weight: 600;
  transition: all 0.3s;
}

.ant-btn-primary:hover {
  background-color: #f8f8f8 !important;
  border-color: #f8f8f8 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ant-btn-primary:active {
  background-color: #efefef !important;
  border-color: #efefef !important;
}

/* Sign-up link in right panel */
.form-area a {
  color: #fff !important;
  text-decoration: underline;
  font-weight: 500;
}

.form-area a:hover {
  color: rgba(255, 255, 255, 0.8) !important;
}

/* Alerts in the form */
.form-area .ant-alert {
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.form-area .ant-alert-error {
  background-color: rgba(255, 77, 79, 0.2);
  border: 1px solid rgba(255, 77, 79, 0.3);
}

.form-area .ant-alert-message {
  color: #fff;
}

.ant-table .ant-table-thead > tr > th {
  background-color: #95101d27;
  padding: 8px 8px !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modern-auth-container {
    flex-direction: column;
  }
  
  .logo-area {
    min-height: 35vh;
    padding: 2rem 1rem;
  }
  
  .welcome-heading {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
  
  .form-area {
    min-height: 65vh;
    padding: 1.5rem;
  }
  
  .logo-container {
    width: 140px;
    height: 140px;
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .welcome-heading {
    font-size: 1.8rem;
  }
  
  .welcome-text {
    font-size: 1rem;
  }
  
  .form-container {
    padding: 1.5rem 1rem;
  }
  
  .logo-container {
    width: 120px;
    height: 120px;
  }
}