/* Modern Sidebar Styles */
.modern-sidebar {
  width: 70px;
  background-color: #fff;
  position: fixed;
  left: 0;
  top: 60px; 
  bottom: 10px;
  transition: width 0.3s ease, transform 0.3s ease;
  z-index: 900;
  border-right: 1px solid #eaeaea;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.05);
}

.sidebar-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  height: 100%;
  padding: 5px 0;
}

.sidebar-item {
  width: 100%;
  margin-bottom: 2px;
}

.sidebar-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  text-decoration: none;
  color: #444;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
}

.sidebar-icon-container {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 4px;
  transition: all 0.2s ease;
}

.sidebar-icon {
  font-size: 18px;
  color: #555;
  transition: all 0.2s ease;
}

.sidebar-label {
  font-size: 10px;
  text-align: center;
  white-space: nowrap;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Active and Hover States */
.sidebar-link:hover .sidebar-icon-container {
  background-color: #f0f0f0;
}

.sidebar-link:hover .sidebar-icon {
  color: #95101e;
}

.sidebar-link.active {
  border-left: 3px solid #95101e;
}

.sidebar-link.active .sidebar-icon-container {
  background-color: #ffeaed;
}

.sidebar-link.active .sidebar-icon {
  color: #95101e;
}

.sidebar-link.active .sidebar-label {
  color: #95101e;
  font-weight: 500;
}

.ant-btn-color-primary {
  color: #95101e;
}

/* Tablet Responsive */
@media (max-width: 768px) {
  .modern-sidebar {
    width: 60px;
  }
  
  .sidebar-icon-container {
    width: 36px;
    height: 36px;
  }
  
  .sidebar-icon {
    font-size: 16px;
  }
  
  .sidebar-label {
    font-size: 9px;
    max-width: 50px;
  }
}

/* Mobile Responsive - Bottom Navigation */
@media (max-width: 480px) {
  .modern-sidebar {
    width: 100%;
    height: 60px;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-right: none;
    border-top: 1px solid #eaeaea;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .sidebar-nav {
    flex-direction: row;
  }
  
  .sidebar-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow-x: auto;
  }
  
  .sidebar-item {
    flex: 1;
    max-width: 70px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
  }
  
  .sidebar-link {
    flex-direction: column;
    justify-content: center;
    padding: 15px 15px;
    border-left: none;
    border-top: 2px solid transparent;
  }
  
  .sidebar-icon-container {
    margin-bottom: 2px;
    width: 32px;
    height: 32px;
  }
  
  .sidebar-label {
    display: none;
  }
  
  .sidebar-link.active {
    border-left: none;
    border-top: 2px solid #95101e;
  }
  
  /* For very small screens, limit number of visible menu items */
  @media (max-width: 360px) {
    .sidebar-item:nth-child(n+5) {
      display: none;
    }
  }
}