.pos-system {
    max-width: 1800px;
    height: 100vh;
    margin-left: 80px;
    margin-top: 70px;
    font-family: Arial, sans-serif;
  }

/* Error message */
.error-message {
  margin: 16px;
  padding: 8px 16px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
}

/* Tabs styling */
.sales-tabs {
  margin: 16px;
}

.sales-tabs .ant-tabs-nav {
  margin-bottom: 16px;
}

/* POS layout */
.pos-layout {
  padding: 0 16px;
}

/* Categories section */
.categories-card {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.category-menu {
  border-right: none;
}

.category-menu .ant-menu-item {
  margin: 0;
  padding-left: 16px;
  border-radius: 4px;
}

.category-menu .ant-menu-item-selected {
  background-color: #e6f7ff;
  font-weight: 500;
}

/* Items section */
.items-card {
  height: calc(100vh - 220px);
  overflow-y: auto;
}

.items-grid {
  padding: 8px 0;
}

.item-card {
  height: 100%;
  transition: all 0.3s;
}

.item-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.item-card .ant-card-meta-title {
  white-space: normal;
  line-height: 1.3;
  margin-bottom: 8px;
}

.item-card .ant-card-body {
  padding: 16px;
}

.item-card .ant-card-actions {
  border-top: none;
  background: #fafafa;
}

/* Cart section */
.cart-card {
  height: calc(100vh - 220px);
  display: flex;
  flex-direction: column;
}

.cart-card .ant-card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
}

.student-select {
  margin-bottom: 16px;
}

.cart-items {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 16px;
  min-height: 200px;
  max-height: calc(100vh - 450px);
}

.cart-item {
  margin-bottom: 8px;
}

.cart-item .ant-card-body {
  padding: 12px;
  height: auto;
}

.item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-summary {
  margin-top: auto;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.total-row {
  margin-bottom: 24px;
}

.pay-button {
  margin-top: 16px;
  height: 48px;
  font-size: 16px;
}

/* Loading container */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

/* Responsive styles */
@media (max-width: 992px) {
  .categories-card,
  .items-card,
  .cart-card {
    height: auto;
    max-height: none;
  }
  
  .cart-items {
    max-height: 300px;
  }
}

@media (max-width: 768px) {
  .pos-layout {
    padding: 0 8px;
  }
  
  .sales-tabs {
    margin: 8px;
  }
  
  .items-card .ant-card-body,
  .cart-card .ant-card-body,
  .categories-card .ant-card-body {
    padding: 12px;
  }
  
  .ant-tabs-tab {
    padding: 8px 12px !important;
    margin: 0 4px 0 0 !important;
  }
  
  .cart-items {
    max-height: 250px;
  }
}

/* Empty cart styling */
.ant-empty {
  margin: 32px 0;
}

/* Animation for adding/removing items */
@keyframes highlightItem {
  0% { background-color: #e6f7ff; }
  100% { background-color: transparent; }
}

.cart-item {
  animation: highlightItem 1s ease-out;
}


/* Sales Report styles */

.sales-report-container {
  padding: 24px;
  background-color: #f0f2f5;
  min-height: calc(100vh - 64px);
}

.sales-report-card {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.sales-report-header {
  margin-bottom: 16px;
}

.sales-summary {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
}

.sales-summary .ant-card-grid {
  box-shadow: none;
  border-right: 1px solid #f0f0f0;
  transition: all 0.3s;
}

.sales-summary .ant-card-grid:last-child {
  border-right: none;
}

.sales-summary .ant-card-grid:hover {
  background-color: #f6f6f6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.filter-controls {
  margin-bottom: 24px;
}


.sales-report-table {
  margin-top: 16px;
}

.sales-report-table .ant-table-thead > tr > th {
  background-color: #fafafa;
  font-weight: 500;
}

.sales-report-table .ant-table-row:hover {
  cursor: pointer;
}

.sales-report-table .ant-table-cell {
  vertical-align: middle;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sales-report-container {
    padding: 16px;
  }
  
  .sales-summary .ant-card-grid {
    width: 50% !important;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .sales-summary .ant-card-grid:nth-child(even) {
    border-right: none;
  }
  
  .sales-summary .ant-card-grid:nth-child(3),
  .sales-summary .ant-card-grid:nth-child(4) {
    border-bottom: none;
  }
  
  .filter-controls .ant-col {
    margin-bottom: 16px;
  }
  
  .export-button {
    margin-top: 8px;
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .sales-summary .ant-card-grid {
    width: 100% !important;
    border-right: none;
  }
  
  .sales-summary .ant-card-grid:last-child {
    border-bottom: none;
  }
}

/* Animation for row highlighting */
@keyframes highlightRow {
  0% { background-color: rgba(24, 144, 255, 0.1); }
  100% { background-color: transparent; }
}

.highlight-row {
  animation: highlightRow 1.5s ease-out;
}

/* Modal styling */
.receipt-modal .ant-modal-content {
  border-radius: 8px;
  overflow: hidden;
}

.receipt-modal .ant-modal-header {
  background-color: #1890ff;
  color: white;
  border-bottom: none;
}

.receipt-modal .ant-modal-title {
  color: white;
}

.receipt-modal .ant-modal-close-x {
  color: white;
}

.modern-pos {
  min-height: 100vh;
}

.main-content {
  padding: 24px;
  background: #f0f2f5;
}

.pos-container {
  height: calc(100vh - 112px);
}

.category-sider {
  background: #ffffff;
  border-right: 1px solid #f0f0f0;
}

.items-content {
  padding: 16px;
  background: #ffffff;
  overflow-y: auto;
}

.cart-sider {
  background: #ffffff;
  border-left: 1px solid #f0f0f0;
  padding: 16px;
}

.item-card {
  text-align: center;
  padding: 8px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  transition: all 0.3s;
}

.item-card:hover {
  border-color: #95101e;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.cart-card {
  height: 100%;
}

.cart-item {
  padding: 8px 0;
  border-bottom: 1px solid #f0f0f0;
}

.summary-line {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.summary-line.total {
  padding: 8px 0;
  border-top: 1px solid #f0f0f0;
}