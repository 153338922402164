.accounting-container {
  padding: 20px;
  margin-top: 60px;
  margin-left: 70px;
}

h1 {
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter-container label {
  margin-right: 10px;
}

.filter-container input,
.filter-container select {
  margin-left: 5px;
}

.filter-button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #337ab7;
  color: white;
  border: none;
  cursor: pointer;
}


.accounting-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.accounting-table th,
.accounting-table td {
  border: 1px solid #ddd;
  padding: 3px;
  text-align: left;
  font-size: 13px;
}

.accounting-table th {
  color: white;
}

.generate-button {
  background-color: #95101e;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.generate-button svg {
  margin-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 5px;
  border: none;
  background-color: #d66969;
  cursor: pointer;
}

.pagination button.active {
  background-color: #95101e;
  color: white;
}

.pagination button:hover {
  background-color: #670f18;
}

/* Style for the totals row */
.accounting-table tbody tr.totals-row {
  background-color: #073611c3;
  color: white; /* Optional: to make the text color white for better readability */
}

.accounting-table tbody tr.totals-row td {
  font-size: 1em; /* Adjust the size as needed */
  font-weight: bold; /* Optional: to make the text bold */
}

.modal,
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 800px;
  max-width: 100%;
}

.modal-header,
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  margin: 0;
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.refund-button {
  background-color: #b72222 !important; /* Red background */
  color: white; /* White text color */
  border: none; /* Remove border */
  padding: 3px 10px;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.refund-button:hover {
  background-color: #a53e3e !important; /* Darker red on hover */
}

.refund-row {
  background-color: #ffdbdb !important; /* Light red background for refunded rows */
}

.receipt-button {
  background-color: #2A4860; 
  padding: 3px 10px;
}

.acc-confirm-button {
  background-color: #8dc572 !important; 
  padding: 3px 10px;
}
.refund-red {
  color: rgb(241, 0, 0);
}
.refund-confirm-button {
  background-color: #bababa; 
  padding: 3px 10px;
}

.refund-watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg); 
  font-size: 8rem; 
  color: rgba(255, 0, 0, 0.3);
  z-index: 1;
  pointer-events: none; 
}

.modal-body {
  margin-bottom: 20px;
}

.modal-body div {
  margin-bottom: 15px;
}

.modal-body label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #333;
}

.modal-body input,
.modal-body textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease;
}

.modal-body input:focus,
.modal-body textarea:focus {
  border-color: #95101e;
  outline: none;
}

.modal-body input::placeholder,
.modal-body textarea::placeholder {
  color: #999;
  font-style: italic;
}

.modal-body textarea {
  min-height: 100px;
  resize: vertical;
}
.register-date {
  align-items: right;
}

/* Responsive styles */
@media (max-width: 768px) {
  .accounting-container {
    padding: 10px;
    margin-left: 0;
  }

  .filter-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-container label,
  .filter-container input,
  .filter-container select {
    margin-bottom: 10px;
    margin-right: 0;
    margin-left: 0;
  }

  .filter-button,
  .export-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .accounting-table th,
  .accounting-table td {
    font-size: 12px;
    padding: 5px;
  }

  .generate-button {
    padding: 5px;
  }

  .pagination button {
    padding: 5px;
    margin: 2px;
  }
  .export-button {
    padding: 5px 10px;
    background-color: #28a745;
    border-radius: 5px;
    color: white;
    border: none;
    width: 70%;
    text-decoration: none;
    cursor: pointer;
  }

  /* Card view for mobile */
  .accounting-table {
    display: none;
  }

  .accounting-cards {
    display: block;
  }

  .accounting-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 60px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .accounting-card div {
    margin-bottom: 5px;
  }

  .accounting-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }
  
  .accounting-card-header .invoiceno {
    text-align: left;
  }
  
  .accounting-card-header .register-date {
    text-align: right;
  }

  .accounting-card-content {
    display: flex;
    flex-wrap: wrap;
  }

  .accounting-card-content > div {
    flex: 1 1 100%;
  }

  .accounting-card .buttons {
    display: flex;
    gap: 5px;
    align-items: center;
  }

}

@media (min-width: 769px) {
  .accounting-cards {
    display: none;
  }
}

@media (max-width: 480px) {
  .filter-container {
    align-items: center;
  }

  .filter-container label,
  .filter-container input,
  .filter-container select,
  .filter-button,
  .export-button {
    width: 100%;
  }

  .accounting-table th,
  .accounting-table td {
    font-size: 10px;
    padding: 3px;
  }

  .generate-button {
    padding: 3px;
  }

  .pagination button {
    padding: 3px;
    margin: 1px;
  }
  .export-button {
    padding: 5px 10px;
    background-color: #28a745;
    border-radius: 5px;
    color: white;
    border: none;
    width: 30%;
    text-decoration: none;
    cursor: pointer;
  }

  .filter-button {
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #337ab7;
    color: white;
    border: none;
    cursor: pointer;
    width: 40%;
}

  /* Mobile specific card view */
  .accounting-card-content > div {
    font-size: 12px;
  }

  .generate-button, .refund-button {
    padding: 3px 8px;
  }
}
/* Accounting.css - Updated styles for the invoice modal */

.invoice-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.invoice-modal {
  background-color: white;
  padding: 25px;
  border-radius: 5px;
  width: 800px;
  max-width: 95%;
  max-height: 95vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.modal-close-button:hover {
  background-color: #e0e0e0;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
}

.company-logo {
  margin-right: 15px;
}

.company-info {
  flex: 1;
}

.company-info h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

.company-info p {
  margin: 2px 0;
}

.invoice-box {
  background-color: rgb(240, 245, 255);
  padding: 15px;
  border-radius: 5px;
  width: 220px;
  text-align: center;
}

.invoice-box h2 {
  color: rgb(70, 70, 255);
  margin: 0 0 10px 0;
}

.invoice-table table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-table th, .invoice-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.invoice-table th {
  background-color: rgb(240, 240, 250);
  color: rgb(50, 50, 50);
  font-weight: bold;
  text-align: left;
}

.invoice-table tr:nth-child(even) {
  background-color: rgb(248, 248, 255);
}

.invoice-summary {
  margin-left: auto;
  width: 300px;
}

.summary-row {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.summary-row.total, .summary-row.remaining {
  font-weight: bold;
  margin-top: 10px;
}

.bank-info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.bank-details {
  margin-left: 10px;
}

.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.signature-box {
  text-align: center;
  width: 45%;
}

.signature-line {
  border-top: 1px solid #000;
  margin-bottom: 5px;
}

.invoice-note {
  text-align: center;
  margin-top: 20px;
  color: #666;
  font-style: italic;
}

.invoice-actions {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.invoice-actions button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.invoice-actions button:hover {
  background-color: #40a9ff;
}

@media print {
  .invoice-modal-overlay {
    position: absolute;
    background: none;
  }
  
  .invoice-modal {
    box-shadow: none;
    max-height: none;
    max-width: none;
    width: 100%;
  }
  
  .modal-close-button, .invoice-actions {
    display: none;
  }
}