/* PayStubModal.css */

.paystub-content {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  max-width: 100%;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.85);
}

.paystub-header-card {
  background-color: #f8fbff !important;
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.company-logo {
  max-width: 120px;
  height: auto;
  display: block;
  margin-bottom: 8px;
}

.company-info {
  line-height: 1.6;
}

.paystub-info {
  background-color: #fff;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

/* Custom styles for Ant Design components */
.ant-descriptions-item-label {
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-table-thead > tr > th {
  font-weight: 600 !important;
}

.ant-table-row:nth-child(odd) {
  background-color: #fafafa;
}

.ant-table-small .ant-table-thead > tr > th {
  padding: 8px 16px !important;
}

.ant-table-summary > tr > td {
  font-weight: 600 !important;
  background-color: #f0f5ff !important;
}

.ant-card-head-title {
  font-weight: 600 !important;
  font-size: 16px !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .paystub-info {
    text-align: left !important;
    margin-top: 16px;
  }
  
  .ant-descriptions-item-label, 
  .ant-descriptions-item-content {
    text-align: left !important;
  }
  
  .ant-table {
    font-size: 12px;
  }
}

/* Print styles */
@media print {
  .paystub-content {
    padding: 0;
  }
  
  .paystub-header-card,
  .ant-card {
    box-shadow: none !important;
    margin-bottom: 10px !important;
  }
  
  .ant-btn {
    display: none !important;
  }
}