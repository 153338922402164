.teacher-container {
  margin-top: 60px;
  margin-left: 70px;

}

.contact-container {
  padding: 0;
  margin-bottom: 60px;
}

.contact-grid {
  display: grid;
  margin-left: 20px;
  margin-left: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.contact {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  position: relative;
  padding-top: 60px; /* Adjust to give enough space for the image */
  overflow: hidden;
}

/* Contact information styling */
.contact-info {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 10px 0;
  color: #4A4AAB;
}

.contact-info div {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Container for the teacher's image */
.teacher-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 3px solid #fff;
  background-color: #f9f9f9;
}

.teacher-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.teacher-img {
  width: 60px; /* Set the image width to fill the container */
  height: 40px; /* Set a fixed height */
  object-fit: cover; /* Ensure the image covers the container, cropping if necessary */
  border-radius: 10%; /* Add some border radius for rounded corners, adjust as needed */
  display: block; /* Ensures the image behaves like a block element */
}



.info-box {
  padding: 40px; /* Adjust this if the image size changes */
  margin-top: 40px; /* Ensure content doesn't overlap with the image */
}

.name {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.specialize-subjects,
.email {
  margin: 5px 0;
  color: #555;
  font-size: 14px;
}

.email {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #95101e;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .teacher-container {
    margin-left: 20px;
    margin-top: 60px;
    margin-right: 20px;
  }

  .contact-grid {
    grid-template-columns: 1fr; /* One card per row */
    gap: 10px;
  }

  .teacher-image-container {
    width: 80px;
    height: 80px;
  }

  .info-box {
    padding: 30px 15px;
    margin-top: 30px;
  }

  .name {
    font-size: 18px;
  }

  .specialize-subjects,
  .email {
    font-size: 12px;
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .contact-grid {
    grid-template-columns: 1fr; /* One card per row */
    gap: 8px;
  }

  .teacher-image-container {
    width: 70px;
    height: 70px;
  }

  .info-box {
    padding: 25px 10px;
    margin-top: 25px;
  }

  .name {
    font-size: 16px;
  }

  .specialize-subjects,
  .email {
    font-size: 11px;
  }
}

/* Container for teacher details */
.teacher-details {
  padding: 70px 20px 20px 20px; /* Increase top padding to accommodate the image */
  text-align: center;
}

.teacher-details h2 {
  margin: 10px 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.teacher-details p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}

.teacher-details .section-title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.teacher-details .contact-info {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 10px 0 20px;
  color: #4A4AAB;
}

.teacher-details .contact-info div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.teacher-details .contact-info div svg {
  font-size: 20px;
}



/* Dashboard */
.teacher-dashboard-container {
  padding-left: 6%;
  padding-top: 80px;
  padding-right: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  }

  .teacher-dashboard-container-t {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .teacher-dashboard-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  }
  
  .teacher-profile,
  .teacher-efficiency,
  .today-classes-t {
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 30%;
  transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .teacher-profile:hover,
  .teacher-efficiency:hover,
  .today-classes-t:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  .teacher-image2 {
  width: 150px; /* This sets a fixed width */
  height: auto; /* This maintains aspect ratio */
  border-radius: 10%; /* This creates a circle shape */
  margin-bottom: 10px;
  display: inline-block; /* Changed from block to inline-block */
  }
  
  .teacher-profile img {
  display: block;
  margin: 0 auto 20px;
  }
  
  .teacher-profile ul {
  list-style: none;
  padding: 0;
  }
  
  .teacher-profile li {
  background-color: #95101e;
  color: white;
  padding: 5px;
  border-radius: 3px;
  margin-bottom: 5px;
  }
  
  .teacher-efficiency {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .teacher-header-tab{
    width: 97%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #73141d;
  padding: 15px 20px;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: nowrap;
  }
  .teacher-header-tab h1{
  font-size: 28px;
  color: #FF6B6B;
  margin-bottom: 10px;
  }

  .teacher-header {
    width: 96.5%;
    display: flex;
    background: #fff;
    padding: 20px;
    border: 2px solid #95101e;
    border-radius: 15px;
    color: #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  .teacher-header:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .teacher-header-info {
    flex: 1;
    margin-left: 20px;
  }
  
  .teacher-header-info h1 {
    margin: 10px;
    font-size: 30px;
    color: #333;
    margin-bottom: 20px;
  }

  .teacher-header-info h6, p {
    margin: 10px;
    font-size: 15px;
    color: #95101e;
    text-transform: capitalize; 
  }

  
  .status {
    font-weight: bold;
    margin-left: 10px;
  }
  
  .status.online {
    background-color: rgba(225, 240, 225, 0.79);
    color: green;
  }
  
  .status.offline {
    background-color: #e0c8c8de;
    color: red;
  }
  
  .teacher-right-header {
    text-align: right;
    color: #95101e;
    margin-top: 20px;
  }
  .icon {
      margin-right: 15px; /* Adjust the spacing between the icon and the text */
}

  .about {
    font-size: 16px;
    color: #474747;
  }
  
  .today-classes-t .classes-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  }
  
  .class-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #fff;
  gap: 5px;
  }
  
  .class-item .class-time {
  font-weight: bold;
  color: #333;
  }
  
  .class-item .class-title {
  margin: 5px 0;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 700;
  color: #95101e;
  }
  
  .class-item .class-link a {
  margin-top: 10px;
  text-decoration: none;
  color: #95101e;
  }
  
  .class-item .class-hours {
  font-size: 0.9rem;
  }
  
  .class-item .class-status.due-soon {
  color: red;
  }
  
  .class-item .class-status.upcoming {
  color: orange;
  }

  .table-scroll-container {
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
  }

  .total-hour {
    display: flex;
    background-color: #95101e;
    padding: 10px 30px 10px 30px;
    border-radius: 15px;
    color: #fff;
    margin-bottom: 50px;
  }
  
  .teacher-education {
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px;
  }
  
  .teacher-schedule {
  width: 100%;
  }
  
  .teacher-schedule .fc {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .additional-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px; /* Added this line to create margin between the boxes */
  margin-bottom: 20px;
  }
  
  .attendance-logs,
  .salary-history {
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 48%;
  }
  
  .attendance-logs .filter-export,
  .salary-history .filter-export {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  }
  
  .filter-export input {
  margin-right: 10px;
  }
  
  .filter-export button {
  background: #28a745;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  }
  
  .attendance-logs table,
  .salary-history table {
  width: 100%;
  border-collapse: collapse;
  }
  
  .attendance-logs th,
  .attendance-logs td,
  .salary-history th,
  .salary-history td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  }
  
  .attendance-logs th,
  .salary-history th {
    background: #95101e;
    color: #fff;
  }
  
  .see-more {
  margin-top: 10px;
  background: #95101e;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  }

  .remarkbox {
    width: 100px;
  }
  .all-classes {
    width: 98%;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .all-classes h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
  }
  
  .all-classes table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  }
  
  .all-classes th, .all-classes td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  }
  
  .all-classes th {
  background-color: #95101e;
  color: white;
  font-weight: bold;
  }
  
  .all-classes tr:nth-child(even) {
  background-color: #f9f9f9;
  }
  
  .all-classes tr:hover {
  background-color: #f1f1f1;
  }
  
  .all-classes button {
  background-color: #95101e;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  }
  
  .all-classes button:hover {
  background-color: #95101e;
  }

  /* Performance Section */
.performance-section {
  margin-top: 20px;
}

.performance-item {
  margin-bottom: 20px;
}

.performance-item h3 {
  margin-bottom: 5px;
}

.progress-bar-container {
  background-color: #f3f3f3;
  border-radius: 13px;
  padding: 3px;
  width: 100%;
}

.progress-bar {
  height: 20px;
  border-radius: 10px;
  text-align: right;
  padding-right: 10px;
  color: white;
  line-height: 20px; /* Center text vertically */
}

.progress-bar-sick {
  background-color: #36A2EB;
}

.progress-bar-personal {
  background-color: #FFCE56;
}

.progress-bar-vacation {
  background-color: #4CAF50;
}

.progress-bar-late {
  background-color: #FF6384;
}

.class-card-t {
  display: none;
}

  
@media (max-width: 768px) {

  .teacher-header-info,
  .teacher-right-header {
    width: 100%;
  }

}
  /* Responsive Design */
  @media (max-width: 1024px) {
  .teacher-dashboard-content,
  .additional-info {
    flex-direction: column;
    align-items: center;
  }
  
  .teacher-profile,
  .teacher-efficiency,
  .today-classes-t,
  .attendance-logs,
  .salary-history {
    width: 80%;
    margin-bottom: 20px;
  }
  }
  
  @media (max-width: 768px) {
    .teacher-dashboard-container-t {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      margin: 15px;
    }
  .teacher-dashboard-content,
  .additional-info {
    flex-direction: column;
    align-items: center;
  }
  
  .teacher-profile,
  .teacher-efficiency,
  .attendance-logs,
  .salary-history {
    width: 100%;
    margin-bottom: 20px;
  }

  .today-classes-t {
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
  }

  .teacher-header-tab{
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-bottom: 60px;
      background-color: #2A4860;
      border-radius: 0px 0px 35px 35px;
    }
  

  .teacher-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .teacher-header-info,
  .teacher-right-header {
    width: 100%;
  }

  .teacher-header-info h1 {
    font-size: 1.25rem;
  }

  .teacher-header-info h6,
  .teacher-header-info p,
  .teacher-right-header .status,
  .teacher-right-header p {
    font-size: 0.8rem;
  }

  .teacher-right-header {
    margin-top: 20px; /* Add margin to move it to the bottom */
  }
  .class-card-t {
    display: flex;
    width: 90%;
    flex-direction: column;
    background: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
  }

  .class-card-t > div {
    margin-bottom: 5px;
  }

  .class-card-t .class-card-header {
    font-weight: bold;
  }

  .schedule-button {
    width: 100%;
  }
  }
  
  @media (max-width: 480px) {
    .teacher-dashboard-container-t {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      margin: 15px;
    }
  .teacher-dashboard-content,
  .additional-info {
    flex-direction: column;
    align-items: center;
  }
  .teacher-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .teacher-header-info,
  .teacher-right-header {
    width: 100%;
  }

  .teacher-header-info h1 {
    font-size: 1.25rem;
  }

  .teacher-header-info h6,
  .teacher-header-info p,
  .teacher-right-header .status,
  .teacher-right-header p {
    font-size: 0.8rem;
  }

  .teacher-right-header {
    margin-top: 20px; /* Add margin to move it to the bottom */
  }
  .all-classes,
  .class-table {
    display: none;
  }
  
  .today-classes-t {
    width: 100% !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
  }
  
  .teacher-profile,
  .teacher-efficiency,
  .today-classes-t,
  .attendance-logs,
  .salary-history {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .teacher-profile h2,
  .teacher-efficiency h2,
  .today-classes-t h2,
  .teacher-education h2,
  .attendance-logs h2,
  .salary-history h2 {
    font-size: 1.2rem;
  }
  
  .class-item .class-time,
  .class-item .class-title,
  .class-item .class-link a,
  .class-item .class-status {
    font-size: 0.9rem;
  }
  .class-card-t {
    display: flex;
    flex-direction: column;
    width: 90%;
    background: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
  }

  .class-card-t > div {
    margin-bottom: 5px;
  }

  .class-card-t .class-card-header {
    font-weight: bold;
  }

  .schedule-button {
    width: 50%;
  }
  }
  
  .teacher-class-groups {
    margin-top: 20px;
  }
  
  .teacher-class-groups h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .teacher-class-groups ul {
    list-style: none;
    padding: 0;
  }
  
  .teacher-class-groups li {
    background: #f9f9f9;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .teacher-class-groups li h3 {
    margin-top: 0;
  }
  
  .teacher-class-groups li p {
    margin: 5px 0;
  }

 