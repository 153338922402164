.doodle-container {
  padding: 20px;
  margin: 60px 20px 80px 70px;
}

.canvas-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools-card {
  height: 100%;
}

.mode-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.brush-size, .color-picker, .text-size, .text-color, .text-font {
  margin-bottom: 15px;
}

.text-settings {
  margin-top: 10px;
  margin-bottom: 10px;
}


/* Media queries for responsiveness */
@media (max-width: 992px) {
  .doodle-container {
    margin: 60px 15px 80px 15px;
  }
}

@media (max-width: 768px) {
  .doodle-container {
    margin: 60px 10px 80px 10px;
  }
}

@media (max-width: 480px) {
  .doodle-container {
    margin: 60px 5px 80px 5px;
    padding: 10px;
  }
  
  .ant-card-body {
    padding: 12px;
  }
  
  .ant-space {
    gap: 8px !important;
  }
}

/* Text overlay styles */
.text-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}