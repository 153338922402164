.inventory-form {
    display: flex;
    flex-direction: column;
}

.inventory-form label {
    font-weight: bold;
    margin-bottom: 5px;
}

.inventory-form input,
.inventory-form textarea,
.inventory-form select {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #fff;
    transition: border-color 0.3s;
}

.inventory-form input:focus,
.inventory-form textarea:focus,
.inventory-form select:focus {
    border-color: #007BFF;
    outline: none;
}

.inventory-form button {
    padding: 10px 15px;
    background-color: #007BFF;
    color: #fff;
    width: 200px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.inventory-form button:hover {
    background-color: #0056b3;
}

.pagination-container {
    display: flex;
    justify-content: left;
    gap: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  
  .page-button {
    padding: 5px 10px;
    margin: 0 2px;
    border: none;
    background-color: #e7e7e7;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.875rem;
  }
  
  .page-button:hover:not(:disabled) {
    background-color: #d7d7d7;
  }
  
  .page-button.disabled,
  .page-button:disabled {
    background-color: #f7f7f7;
    color: #000;
    cursor: not-allowed;
  }
  
  .page-button.active {
    background-color: #95101e;
    color: white;
  }

  .adjust-button {
    padding: 5px !important;
    font-size: 12px !important;
    background-color: #95101e !important;
  }

  .inventory-container {
    margin-top: 16px;
  }
  
  .inventory-card {
    border-radius: 8px;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
                0 3px 6px 0 rgba(0, 0, 0, 0.12),
                0 5px 12px 4px rgba(0, 0, 0, 0.09);
  }
  
  .filter-section {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .low-stock-row {
    background-color: #fff1f0;
  }
  
  .low-stock-row:hover td {
    background-color: #ffccc7 !important;
  }
  
  .quantity-adjuster {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .quantity-adjuster .ant-input-number {
    flex: 1;
  }
  
  .error-message {
    background-color: #fff1f0;
    padding: 12px 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    border: 1px solid #ffccc7;
  }
  
  /* Table styles */
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: rgba(24, 144, 255, 0.1);
  }
  
  .ant-table-row .ant-badge-count {
    min-width: 36px;
    height: 22px;
    line-height: 22px;
    padding: 0 6px;
    color: #fff;
    font-weight: normal;
  }
  
  /* Badge styles */
  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
  
  /* Animation for quantity updates */
  @keyframes highlight {
    0% { background-color: #fffbe6; }
    100% { background-color: transparent; }
  }
  
  .highlight-row {
    animation: highlight 2s ease-out;
  }
  
  /* Modal styling */
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 12px;
  }
  
  .ant-modal-title {
    font-weight: 600;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .main-content {
      padding: 16px;
    }
    
    .filter-section {
      flex-direction: column;
      gap: 12px;
    }
    
    .filter-section .ant-input-affix-wrapper,
    .filter-section .ant-select {
      width: 100% !important;
    }
    
    .quantity-adjuster {
      flex-direction: column;
      align-items: stretch;
    }
    
    .quantity-adjuster .ant-btn:first-child {
      border-radius: 2px 2px 0 0;
    }
    
    .quantity-adjuster .ant-btn:last-child {
      border-radius: 0 0 2px 2px;
    }
    
    .quantity-adjuster .ant-input-number {
      width: 100% !important;
    }
  }
  
  /* Select status styling */
  .ant-select-item-option-content .ant-badge-status-dot {
    display: inline-block;
    margin-right: 6px;
  }
  
  .ant-select-item-option-content .ant-badge-status-text {
    vertical-align: middle;
  }
  
  /* Action buttons */
  .ant-btn-primary {
    background-color: #95101e;
  }
  
  .ant-btn-primary:hover, 
  .ant-btn-primary:focus {
    background-color: #6b111a;
  }
  
  /* Card header */
  .ant-card-head {
    min-height: 56px;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    background: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
  }
  
  .ant-card-head-title {
    padding: 16px 0;
  }
  
  .ant-card-extra {
    padding: 16px 0;
  }
  
  /* Form styling */
  .ant-form-item-label > label {
    font-weight: 500;
  }

  /* Additional styles for InventoryReport */

.inventory-report-container {
  margin-top: 16px;
}

.inventory-report-table {
  margin-top: 16px;
}

.inventory-report-table .ant-table-summary {
  background-color: #fafafa;
}

.inventory-report-table .ant-table-summary-row td {
  padding: 12px 16px;
  font-weight: 500;
}


/* Date range picker */
.ant-picker-range {
  width: 350px;
}

/* Transaction tag styles */
.ant-tag {
  font-size: 12px;
  padding: 2px 8px;
  margin-right: 0;
}

/* Table row hover effect */
.inventory-report-table .ant-table-tbody > tr:hover > td {
  background-color: rgba(24, 144, 255, 0.05);
}

/* Custom styling for quantities */
.positive-quantity {
  color: #52c41a;
  font-weight: 500;
}

.negative-quantity {
  color: #f5222d;
  font-weight: 500;
}

/* Filter dropdown styling */
.ant-table-filter-dropdown {
  padding: 12px;
  border-radius: 4px;
}

.ant-table-filter-dropdown input {
  padding: 8px;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.ant-table-filter-dropdown .ant-btn {
  margin-top: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filter-section {
    flex-direction: column;
  }
  
  .filter-section .ant-space {
    margin-bottom: 16px;
    flex-wrap: wrap;
  }
  
  .filter-section .ant-btn {
    margin-top: 8px;
  }
  
  .ant-picker-range {
    width: 100%;
  }
  
  .export-button {
    margin-top: 8px;
    margin-left: 0;
    width: 100%;
  }
}

/* Table summary row */
.ant-table-summary {
  position: sticky;
  bottom: 0;
  z-index: 2;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.03);
}

/* Document number styles */
.document-number {
  font-family: 'Roboto Mono', monospace;
  background-color: #f5f5f5;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
}

/* Tooltip enhancements */
.ant-tooltip {
  max-width: 500px;
}

.ant-tooltip-inner {
  font-size: 12px;
  padding: 8px 12px;
}

