.my-performance {
  display: flex;
  width: 100%;
}

.performance-content {
  flex-grow: 1;
  padding: 75px 20px 60px 90px;
  width: 90%;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 12px;
  padding: 20px 20px;
  background-color: #95101e;
  overflow-x: auto; 
  white-space: nowrap; 
}

.tab-button {
  flex: none;
  background-color: white !important;
  color: #333;
  margin-right: 10px;
  border: none;
  padding: 10px 20px;
  font-size: 22px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.tab-button:not(.active):hover {
  background-color: #e9ecef;
}

.tab-button.active {
  background-color: #FF6B6B !important; 
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.tab-content {
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.classes-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 15px;
}

.class-box {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 350px; /* Ensure minimum width */
}

.class-box-header {
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

/* Individual Status Classes */
.class-box.present .class-box-header {
  background-color: #236f33; 
}

.class-box.late .class-box-header {
  background-color: #f0ad4e; 
}

.class-box.absent .class-box-header {
  background-color: #be6464; 
}

.class-box.rescheduled .class-box-header { /* New Status */
  background-color: #d3d3d3; /* Light Grey for Rescheduled */
}

.class-box.default .class-box-header {
  background-color: #337ab7; 
}

.class-box-body {
  padding: 15px;
  color: #333;
  text-align: left;
  font-size: 17px;
}

.class-box-body strong {
  display: block;
  margin-bottom: 8px;
}

.class-box-body .date-time-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.class-box-body .date-time-row > div {
  flex: 1;
  margin-right: 15px;
  margin-bottom: 10px;
}

.class-box-body .date-time-row > div:last-child {
  margin-right: 0;
}

.class-box-body .status {
  margin-top: 10px;
  font-weight: bold;
  color: #666;
}

.class-box-body .comment {
  margin-top: 20px;
  font-style: italic;
  color: #999;
}

.status-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff; 
  color: rgb(167, 24, 24);
  border-radius: 5px;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
}

.class-box.present .status-icon {
  background-color: #fff; 
  color: rgb(12, 145, 21);
}

.class-box.late .status-icon {
  background-color: #fff; 
  color: #f0ad4e;
}

.class-box.absent .status-icon {
  background-color: #fff; 
  color: #be6464;
}

.class-box.rescheduled .status-icon { /* New Status */
  background-color: #fff; 
  color: #d3d3d3;
}

.class-box.default .status-icon {
  background-color: #fff; 
  color: #337ab7;
}

.status-icon .fa {
  margin-right: 5px;
}
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.progress-circle {
  width: 100px;
  margin: 0 auto 20px auto;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .performance-content {
    padding: 60px 0px;
    width: 100%;
  }

  .classes-container {
    grid-template-columns: 1fr; /* Stack the classes vertically on mobile */
  }

  .class-box {
    width: 100%; /* Make class box take full width on mobile */
  }

  .class-box-header {
    padding: 15px;
    font-size: 16px;
  }

  .class-box-body {
    padding: 10px;
    font-size: 14px;
  }

  .status-icon {
    font-size: 10px;
    padding: 4px 8px;
  }
  .progress-container {
    padding: 10px;
    margin-bottom: 15px;
  }
}
