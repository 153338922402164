/* Chat.css */
.chat-page {
  display: flex;
  height: 85vh;
  width: 93%;
  margin-left: 80px;
  margin-top: 80px;
}

/* Chat list styles */
.chat-list {
  width: 25%;
  background-color: #fff;
  border-radius: 15px 0px 0px 15px;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  padding: 20px;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-item:hover {
  background-color: #dbdbdb;
  border-radius: 15px;
}

.chat-item.active {
  background-color: #dbdbdb; 
  border-radius: 15px;
}

.user-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  margin-right: 15px;
}

.user-chat-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  color: #333;
  position: relative;
}

.status-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  top: 0;
}

.online {
  background-color: #4CAF50; /* Green for online */
}

.offline {
  background-color: #fba186; 
}

.user-nickname {
  font-size: 14px;
  color: #777;
}

.user-last-online {
  font-size: 10px;
  color: #999;
  margin-top: 5px;
}

/* Chat window styles */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f9f9f9;
  border-radius: 0px 15px 15px 0px;
}

.chat-messages {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-message {
  font-family: 'Sarabun', sans-serif;
  font-size: 16px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 8px;
  max-width: 75%;
  word-wrap: break-word;
  line-height: 1.4;
}

.chat-message.sent {
  background-color: #39a24e;
  color: white;
  align-self: flex-end; 
  text-align: right;
}

.chat-message.received {
  background-color: #F1F1F1; /* Light grey background for received messages */
  color: black;
  align-self: flex-start; /* Align received messages to the left */
  text-align: left;
}

.chat-message strong {
  font-weight: bold;
}

.chat-input {
  padding: 20px;
  background-color: #fff;
  border-radius: 0px 0px 15px 0px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e0e0e0;
}

.chat-input input[type="text"] {
  flex: 1;
  padding: 12px 20px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-right: 10px;
}

.chat-input button {
  padding: 12px 20px;
  background-color: #95101e; 
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Chat placeholder styles */
.chat-placeholder {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #888;
  background-color: #ffffff;
  border-radius: 0px 15px 15px 0px;
}

.unread-dot {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: red; 
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

.message-status {
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
  color: rgb(195, 195, 195); 
}

.message-status.read {
  color: #4cff52; 
}

.message-status > .single-check {
  display: inline-block;
  position: relative;
}

.message-status > .single-check::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 12px;
  border-right: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(45deg);
  left: 0;
  top: 0;
}

/* Responsive styling */
@media (max-width: 768px) {
  .chat-page {
      flex-direction: column;
      margin-left: 0;
      margin-top: 0;
      width: 100%;
      height: 89vh; /* Full screen height for mobile */
  }

  .chat-list {
      width: 89%; /* Full width for horizontal scrolling */
      height: 5vh; /* Fixed height for the user list, 1/5 of the screen */
      margin-bottom: 0;
      margin-top: 70px;
      overflow-x: auto; /* Enable horizontal scrolling */
      overflow-y: hidden; /* Hide vertical overflow */
      white-space: nowrap; /* Prevent wrapping of the chat items */
      display: flex; /* Align items horizontally */
      align-items: center; /* Vertically center items */
      /* Override styles for mobile view */
      background-color: transparent; /* Remove background color */
      border-radius: 0; /* Remove border radius */
      border-right: none; /* Remove right border */
  }

  .chat-item {
      display: inline-block; /* Align items horizontally */
      width: 80px; /* Equal width for each user box */
      margin-right: 10px; /* Spacing between items */
      border-bottom: none; /* Remove bottom border */
      border-right: 1px solid #f1f1f1; /* Optional: Add a right border for separation */
      text-align: center; /* Center align the text */
      flex-shrink: 0; /* Prevent shrinking */
  }

  .user-circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: bold;
      margin: 0 auto 5px auto; /* Center the circle and add some margin below */
  }

  .user-name {
      font-size: 12px; /* Smaller font size for the username */
      color: #333;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .user-last-online {
      display: none; /* Hide the last online status */
  }

  .chat-window {
      flex: 1;
      margin-top: 10px;
      margin-bottom: 70px;
      height: 70vh !important;
      border-radius: 0px 0px 0px 0%; 
      width: 100%; 
      padding-top: 10px; 
  }

  .chat-messages {
      padding: 20px;
  }

  .chat-input {
      padding: 10px;
  }

  .chat-input input[type="text"] {
      font-size: 14px;
      padding: 10px;
  }

  .chat-input button {
      padding: 10px;
      font-size: 14px;
  }
}
