/* LanguageSwitcher.css */
.language-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .language-dropdown {
    padding: 5px 5px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
  }
  
  .language-dropdown:hover {
    background-color: #fff;
    color: #333;
  }
  
  .select-language {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 8px 36px 8px 12px;
    font-size: 14px;
    font-weight: 500;
    color: #444;
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px;
  }
  
  .select-language:hover {
    border-color: #d0d0d0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }
  
  .select-language:focus {
    outline: none;
    border-color: #95101e;
    box-shadow: 0 0 0 3px rgba(149, 16, 30, 0.15);
  }
  
  /* Language option styling */
  .select-language option {
    padding: 8px;
    font-size: 14px;
    background-color: #fff;
    color: #444;
  }
  
  /* Styling for dark mode (optional) */
  @media (prefers-color-scheme: dark) {
    .select-language {
      background-color: #2a2a2a;
      border-color: #444;
      color: #e0e0e0;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23aaa' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    }
    
    .select-language:hover {
      border-color: #555;
    }
    
    .select-language option {
      background-color: #2a2a2a;
      color: #e0e0e0;
    }
  }
  
  /* For smaller screens */
  @media (max-width: 480px) {
    .select-language {
      padding: 6px 30px 6px 8px;
      font-size: 13px;
      background-size: 14px;
    }
  }