.class-container {
  margin-left: 80px;
  margin-right: 20px;
  margin-bottom: 60px;
  margin-top: 65px;
}


.form-and-preview {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.filter-container,
.preview-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-sizing: border-box;
}

.transparent-table {
  width: 100%;
}

.transparent-table th,
.transparent-table td {
  padding: 5px; 
  border-bottom: none !important;
  box-shadow: none;
}

/* Additional styling as needed */

.new-class-container {
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.new-class-container,
.preview-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.class-calendar-container {
  width: 100%; /* Adjusted width to fit the filter container */
  height: calc(100vh); /* Adjust based on your header height */
  padding: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  margin-bottom: 40px;
  background-color: #fff;
  display: inline-block; /* Display inline-block to align next to filter container */
  vertical-align: top; /* Align top with filter container */
}

.filter-container-allclass {
  display: inline-block;
  width: 15%;
  vertical-align: top;
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 2%;
  box-sizing: border-box;
  transition: background-color 0.3s;
  max-height: 800px; /* Set the maximum height */
  overflow-y: auto; /* Enable vertical scrollbar if content exceeds max-height */
}


.filter-container-courses {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}


.filter-container h2,
.new-class-container h2,
.preview-container h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.filters input {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.print-button {
  padding: 5px;
  display: inline-block;
  width: fit-content;
  position: relative;
  left: 0;
  margin-bottom: 10px; /* optional: adds some space below the button */
}

.pdf-upload-button {
  background-color: #D32F2F !important; /* Adobe PDF red color */
  color: white !important; /* Text color */
  border: none; /* Remove default border */
  padding: 5px 10px !important; /* Padding for button size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Cursor changes to pointer on hover */
  font-size: 14px; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.pdf-upload-button:hover {
  background-color: #C62828 !important; /* Darker red on hover */
}



.new-class-container form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: 500;
  color: #333;
  margin-bottom: 3px;
}

.form-row.schedule-slot.compact-row {
  display: flex;
  flex-wrap: nowrap; /* Ensure no wrapping happens */
  justify-content: space-between;
  align-items: center;
  gap: 10px; /* Space between elements */
  margin-bottom: 10px; /* Spacing between rows */
}

.form-group.compact-group {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-width: 80px; /* Adjust this value based on the width you need */
}

.form-group.compact-group label {
  font-size: 12px; /* Smaller label size */
  margin-bottom: 3px;
  text-align: left;
}

.form-group.compact-group input, 
.form-group.compact-group select {
  padding: 5px;
  font-size: 12px;
  width: 100%; /* Ensure the input/select stretches to the width of the container */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.compact-delete {
  display: flex;
  align-items: center;
}

.compact-delete button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 2px; /* Adjust padding for better button size */
}

.compact-delete .fa-trash {
  color: #fff; /* Set the delete icon color */
  font-size: 15px; /* Adjust size of the icon */
}

.month-tabs-container {
  margin-bottom: 24px;
}

.month-tabs-container .ant-tabs-nav {
  margin: 0;
}

.month-tabs-container .ant-tabs-nav::before {
  border: none;
}

.month-tabs-container .ant-tabs-tab {
  padding: 8px 16px;
  margin: 0 !important;
  transition: all 0.3s;
  border-radius: 0;
  font-weight: 500;
  color: #666;
}

.month-tabs-container .ant-tabs-tab:hover {
  color: #1890ff;
  background: #f5f5f5;
}

.month-tabs-container .ant-tabs-tab-active {
  background: #1890ff !important;
}

.month-tabs-container .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.month-tabs-container .ant-tabs-ink-bar {
  display: none;
}

.month-tabs-container .ant-tabs-nav-list {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 4px;
  gap: 4px;
}
.new-class-container input,
.new-class-container textarea,
.new-class-container select,
.new-class-container button {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff !important;
  color: #333;
  font-size: 14px;
  outline: none;
}

.new-class-container input:focus,
.new-class-container textarea:focus,
.new-class-container select:focus {
  border-color: #95101e;
}

.form-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 20px;
}

.form-wrap {
  background-color: #e8e8e8;
  padding: 10px;
  border-radius: 15px;
}

.wrapped-cell {
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.4;
  min-height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.new-class-container button.add-time-slot,
.new-class-container button.submit-button,
.new-class-container button.delete-time-slot {
  background-color: #95101e !important;
  color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.new-class-container button.add-time-slot:hover,
.new-class-container button.submit-button:hover,
.new-class-container button.delete-time-slot:hover {
  background-color: #681018 !important;
}

.new-class-container button.add-time-slot {
  background-color: #28a745 !important;
}

.new-class-container button.add-time-slot:hover {
  background-color: #218838 !important;
}

.new-class-container button.delete-time-slot {
  background-color: #dc3545 !important;
  width: 100%;
  margin-top: 10px;

}


.new-class-container button.delete-time-slot:hover {
  background-color: #c82333 !important;
}

.schedule-button {
  background-color: #be6464;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.delete-button {
  background-color: #dc3545;
}

.schedule-button:hover {
  background-color: #793d3d;
}
.end-date input,
.total-hours input {
  background-color: #e9f7ff;
  border-color: #95101e;
}

/* Container for checkboxes */
.checkbox-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  color: #333;
}

.checkbox-container input {
  display: none;
}

.checkbox-container .checkmark {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.checkbox-container input:checked + .checkmark {
  background-color: #95101e;
  border-color: #95101e;
}

.checkbox-container .checkmark::after {
  content: '';
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked + .checkmark::after {
  display: block;
}

.preview-container .preview-content {
  background-color: #77bbc479;
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.preview-container .preview-content p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.checkbox-container {
  display: flex;
  gap: 20px; /* Space between checkboxes */
  margin-bottom: 20px;
}

/*Responsive*/
@media (max-width: 1024px) {
  .form-and-preview {
    flex-direction: column;
  }

  .new-class-container,
  .preview-container,
  .class-calendar-container,
  .filter-container-allclass {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .form-and-preview,
  .filter-container-courses {
    flex-direction: column;
  }

  .filter-container,
  .class-container,
  .new-class-container,
  .preview-container,
  .filter-container-allclass {
    width: 97%;
    margin: 40px 5px 60px 5px;
  }

  .class-table-containe {
    width: 100%;
    margin: 0px;
  }

  .form-group {
    flex-direction: column;
  }
}


/* CLASS DETAILS */
.class-details-container {
  padding: 5rem 1rem 5rem 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.class-header-card {
  margin-bottom: 24px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.class-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.class-code {
  margin: 0 !important;
  padding: 8px 24px;
  background: #e6f4ff;
  border-radius: 8px;
  color: #95101e !important; 
}

.info-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 32px;
  padding: 0 16px;
}

.info-section {
  flex: 1;
  max-width: 400px;
  padding: 16px 24px;
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.section-icon {
  font-size: 16px;
  color: #8c8c8c;
}

.section-label {
  font-size: 12px;
  font-weight: 500;
  color: #8c8c8c;
  letter-spacing: 0.5px;
}

.section-content {
  font-size: 16px;
  color: #262626;
  font-weight: 500;
}



.info-attendance-row {
  display: flex;
  gap: 1rem;
}

.class-info-container,
.attendance-container,
.class-messages-container,
.class-students-container {
  flex: 1;
  background-color: #fff;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.attendance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attendance-stats,
.attendance-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
}



.attendance-stats p {
  margin: 5px 0;
}

.chapter-select {
  width: 100%;
  min-height: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.chapter-select option {
  padding: 4px;
}

.attendance-dialog {
  width: 90%;
  max-width: 1200px;
  max-height: 80vh;
  overflow-y: auto;
}

.attendance-table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th,
.attendance-table td {
  padding: 12px;
  border: 1px solid #ddd;
}

.attendance-table th {
  background-color: #f5f5f5;
  font-weight: 600;
}

.attendance-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}



.class-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.class-info-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e3a8a;
}
.info-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
}

.info-icon {
  width: 36px;
  height: 36px;
  background-color: #e2e8f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #475569;
}

.info-icon svg {
  font-size: 1rem;
}

.info-content {
  flex: 1;
}

.info-label {
  font-size: 0.9rem;
  color: #64748b;
  margin-bottom: 2px;
}

.info-value {
  font-weight: 500;
  color: #334155;
}


@media (prefers-color-scheme: dark) {
  .class-info-container {
    background: #1e293b;
  }

  .class-info-header h3 {
    color: #e2e8f0;
  }

  .info-icon {
    background-color: #475569;
    color: #e2e8f0;
  }

  .info-label {
    color: #94a3b8;
  }

  .info-value {
    color: #e2e8f0;
  }

  .schedule-button {
    background-color: #95101e;
  }

  .schedule-button:hover {
    background-color: #5e1018;
  }
}
.class-messages-container {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messages-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.messages-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e3a8a;
}

.message-input-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.message-textarea {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  resize: none;
  height: 40px; 
  min-height: 20px;
  font-size: 0.95rem;
  color: #334155;
  background-color: #f8fafc;
  transition: border-color 0.2s;
  line-height: 1.2;
}

.message-textarea:focus {
  outline: none;
  border-color: #95101e;
  background-color: white;
}

.send-button {
  padding: 10px 24px;
  height: 45px; /* Match textarea height */
  background-color: #95101e;
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s;
}

.send-button:hover {
  background-color: #364be4;
}

.send-button svg {
  font-size: 1rem;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 8px;
}

.message-list::-webkit-scrollbar {
  width: 6px;
}

.message-list::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 3px;
}

.message-list::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.message-item {
  background-color: #f8fafc;
  padding: 12px !important;
  border-radius: 8px;
  position: relative;
}

.message-sender {
  color: #1e3a8a;
  font-weight: 600;
  margin-bottom: 4px;
}

.message-content {
  color: #334155;
  line-height: 1.5;
  margin-bottom: 4px;
}

.message-time {
  display: block;
  color: #64748b;
  font-size: 0.5rem;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .class-messages-container {
    background: #1e293b;
  }

  .messages-header h3 {
    color: #e2e8f0;
  }

  .message-textarea {
    background-color: #334155;
    border-color: #475569;
    color: #e2e8f0;
  }

  .message-textarea:focus {
    background-color: #1e293b;
    border-color: #3b82f6;
  }

  .message-item {
    background-color: #334155;
  }

  .message-sender {
    color: #60a5fa;
  }

  .message-content {
    color: #e2e8f0;
  }

  .message-time {
    color: #94a3b8;
    font-size: 0.5rem;
  }

  .send-button {
    background-color: #74101a;
  }

  .send-button:hover {
    background-color: #95101e;
  }
}
 
.class-schedule-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}

.class-schedule-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
  width: calc(32%);
}

.class-schedule-card h3 {
  margin-top: 0;
}

.class-schedule-card p {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.class-schedule-card textarea {
  width: 95%;
  height: 100px;
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  resize: none; /* Prevent resizing */
  text-align: left;
}


.class-schedule-card button {
  background-color: #95101e;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin: 5px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.class-schedule-card button:hover {
  background-color: #95101e;
}

.class-schedule-card a {
  color: #95101e;
  text-decoration: none;
}

.class-schedule-card a:hover {
  text-decoration: underline;
}

.class-schedule-card textarea[disabled] {
  background-color: #f0f0f0;
  cursor: not-allowed;
}

.textarea1 {
  resize: none;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
}
.class-id {
  font-size: 10px;
  background-color: #ffffff;
  color: #1890ff;  /* Ant Design's default blue */
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 600;
  margin-right: 2px;
}

.change-logs-table {
  border-collapse: collapse;
  width: 100%;
}

.change-logs-table td {
  padding: 8px;
  border: 1px solid #e0e0e0; /* Subtle borders */
  vertical-align: middle; /* Align text vertically */
}

.change-label {
  font-weight: bold;
  background-color: #f1f1f1; /* Light gray background for labels */
  text-align: right; /* Align label text to the right */
}

.change-content {
  color: #333; /* Dark gray text */
  text-align: left; /* Align content text to the left */
}


/* Responsive Design */

@media (max-width: 1024px) {
  .class-schedule-card {
    width: calc(50% - 1rem); /* For 2 cards per row */
  }

  .info-attendance-row {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .class-header-card .ant-card-body {
    padding: 20px;
  }

  .class-code {
    font-size: 24px;
    padding: 8px 16px;
  }

  .detail-text {
    font-size: 18px !important;
  }

  .detail-item {
    padding: 12px;
  }
  .class-details-main {
    flex-direction: column;
  }
  .info-container {
    flex-direction: column;
    gap: 16px;
  }

  .info-section {
    max-width: none;
  }

  .divider {
    display: none;
  }

  .schedule-grid {
    flex-direction: column;
  }
  .class-details-container {
    padding: 3.76rem 0rem;
  }

  .class-schedule-card {
    width: 100%; /* For 1 card per row */
  }

  .class-messages-container textarea {
    width: 90%;
  }

  .attendance-container {
    width: 100%;
  }

  .class-info-container,
  .attendance-container,
  .class-messages-container,
  .class-students-container {
    width: 100%;
    margin-bottom: 5rem;
  }
}
.activity-item p {
  color: #222;
}

.activity-item strong {
  color: #073611;
}

.class-students-container li {
  color: #222;
}

.activity-circle {
  background-color: #8dc572; /* Match the primary color of the activities */
}

.teacher-calendar-and-placeholder {
  display: flex;
  width: 100%;
}
.teacher-list {
  max-height: 300px;
  overflow-y: auto;
}
.teacher-calendar-container, .placeholder {
  box-sizing: border-box;
}

.teacher-calendar-container {
  width: 60%;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.teacher-schedule-event {
  opacity: 0.8;
}

/* New top filter styles */
.top-filter-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.teacher-select-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
}

.teacher-search {
  width: 200px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.teacher-select {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.teacher-checkbox {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: white;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.color-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

/* FullCalendar customizations */
.fc-daygrid-event-harness {
  margin-top: 0 !important;
}

.fc-daygrid-day-events {
  margin-bottom: 0 !important;
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: relative !important;
  min-height: auto !important;
}

/* Remove +more link */
.fc-daygrid-more-link {
  display: none !important;
}

/* Make events in month view fully visible */
.fc-daygrid-event {
  white-space: normal !important;
  overflow: visible !important;
  max-height: none !important;
}

/* Make events in month view more visible */
.fc-daygrid-day-events {
  min-height: 1.7em;
  margin-bottom: 2px;
}

.fc-daygrid-event-harness {
  margin-top: 1px !important;
}

.placeholder {
  padding: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; 
  color: #8f8f8f;
}

.classes-table-container {
  overflow-x: auto; /* Allows table to scroll horizontally on small screens */
  overflow-y: auto;
}

.classes-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: #ffffff;
  border-radius: 8px;
  overflow-x: scroll;
}
.classes-table th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.classes-table th {
font-weight: bold;
text-transform: uppercase;
}

.classes-table thead th {
  padding: 10px;
  text-align: left;

}

.classes-table td {
  font-size: 13px;
  border-bottom: 1px solid #dddddd;
}

.classes-table tr:hover {
  background-color: #f5f5f5;
}

.classes-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;

}

.classes-table tbody tr:nth-child(even) {
  background-color: #e9e9e9;

}

.classes-table tbody td {
  vertical-align: middle; /* Center content vertically */
}

.classes-table tr:hover {
    background-color: #f5f5f5;
}

.pagination-container {
  display: flex;
  justify-content: left;
  gap: 5px; /* Smaller gap for smaller buttons */
  margin-bottom: 20px;
  margin-top: 20px;
}

.page-button {
  padding: 5px 10px; /* Smaller padding for smaller buttons */
  margin: 0 2px; /* Smaller margin for smaller buttons */
  border: none;
  background-color: #e7e7e7;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.875rem; /* Smaller font size */
}

.page-button:hover:not(:disabled) {
  background-color: #d7d7d7;
}

.page-button.disabled,
.page-button:disabled {
  background-color: #f7f7f7 !important;
  color: #aaa;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #95101e;
  color: white;
}

.refresh-container {
  display: flex;
  justify-content: left;
  padding: 5px 5px;
}

.refresh-button {
  background-color: #c9c9c9 !important;
  border: none;
  color: white;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
}

.attendance-table {
  width: 90%;
  border-collapse: collapse;
  margin: 20px;

}

.attendance-table th,
.attendance-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.attendance-table th {
  background-color: #f2f2f2;
  color: black;
}

.attendance-table td select {
  width: 100%;
}


.schedule-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.rating-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rating-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rating-label {
  flex: 1;
}

.rating-stars {
  flex: 2;
}

.rating-comment {
  flex: 3;
}

.rating-comment textarea {
  width: 45%;
  height: 60px;
}
.all-class-card-list {
  display: none;
}

.attendance-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end; /* Align to right */
  z-index: 1000;
}

.attendance-dialog {
  background: white;
  width: 700px; /* Fixed width for the panel */
  height: 100vh;
  max-width: 90%; /* For mobile responsiveness */
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
  position: relative;
  display: flex;
  flex-direction: column;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.attendance-dialog-header {
  padding: 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.attendance-dialog-content {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 5px 10px;
  border-radius: 4px;
}

.close-button:hover {
  background-color: #eee;
  color: #333;
}

.attendance-dialog table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background: white;
}

.attendance-dialog th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.attendance-dialog th,
.attendance-dialog td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.attendance-dialog select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.attendance-dialog textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 60px;
  resize: vertical;
}

.save-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  position: sticky;
  bottom: 20px;
  margin: 0 auto;
  display: block;
  width: calc(100% - 40px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.save-button:hover {
  background-color: #0056b3;
}

.attendance-button {
  margin-top: 10px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;
}

.attendance-button:not(:disabled):hover {
  background-color: #f8f9fa;
}

.attendance-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.class-code-column {
  max-width: 150px;             /* Adjust max width as needed */
  white-space: normal;          /* Allow text to wrap */
  overflow: hidden;             /* Hide overflow */
  display: -webkit-box;         /* Use flexbox layout */
  -webkit-box-orient: vertical; /* Set box orientation to vertical */
}
.attendance-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.attendance-dialog {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
}

.attendance-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.attendance-dialog-header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px 10px;
}

.attendance-table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th {
  padding: 12px;
  text-align: left;
}

.attendance-table td {
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.attendance-select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}

.attendance-input {
  width: 80%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}



.homework-status {
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.homework-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
}

.edited-timestamp {
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
}

.attendance-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.edit-button {
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  cursor: pointer;
  transition: all 0.2s;
}

.edit-button:hover {
  background-color: #e9ecef;
}
.attendance-save-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #95101e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.attendance-save-button:hover {
  background-color: #691019;
}

.attendance-save-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.attendance-status-saved {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #4b5563;
}

.attendance-timestamp {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #6b7280;
  font-size: 14px;
}

.icon-lock, .icon-clock, .save-icon {
  font-size: 16px;
}

.save-icon {
  color: white;
}

/* Alternating row colors */
.attendance-table tbody tr:nth-child(even) {
  background-color: #f9fafb;
}

.attendance-table tbody tr:hover {
  background-color: #f3f4f6;
}

/* Previous CSS styles remain the same */

/* Add these new styles for status colors */
.attendance-status-saved {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 4px;
  font-weight: 500;
}

.attendance-status-saved.status-present {
  background-color: rgba(34, 197, 94, 0.1);  /* light green */
  color: #16a34a;
}

.attendance-status-saved.status-late {
  background-color: rgba(251, 146, 60, 0.1);  /* light orange */
  color: #ea580c;
}

.attendance-status-saved.status-absent {
  background-color: rgba(239, 68, 68, 0.1);  /* light red */
  color: #dc2626;
}

/* Style for the select dropdown */
.attendance-select option[value="Present"] {
  background-color: rgba(34, 197, 94, 0.1);
  color: #16a34a;
}

.attendance-select option[value="Late"] {
  background-color: rgba(251, 146, 60, 0.1);
  color: #ea580c;
}

.attendance-select option[value="Absent"] {
  background-color: rgba(239, 68, 68, 0.1);
  color: #dc2626;
}


.registered-students-container {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  height: 100%;
}

.registered-students-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.registered-students-header h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e3a8a;
}

.student-count {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background-color: #f1f5f9;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #64748b;
}

.student-count svg {
  font-size: 0.9rem;
}

.students-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: calc(100% - 70px);
  overflow-y: auto;
}

.students-list::-webkit-scrollbar {
  width: 6px;
}

.students-list::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 3px;
}

.students-list::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 3px;
}

.student-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
}

.student-avatar {
  width: 36px;
  height: 36px;
  background-color: #e2e8f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #475569;
  font-size: 1rem;
}

.student-info {
  flex: 1;
}

.student-name {
  font-weight: 500;
  color: #334155;
  margin-bottom: 2px;
}

.student-nickname {
  font-size: 0.9rem;
  color: #64748b;
}

/* Add this for dark mode support if needed */
@media (prefers-color-scheme: dark) {
  .registered-students-container {
    background: #1e293b;
  }

  .registered-students-header h3 {
    color: #e2e8f0;
  }

  .student-count {
    background-color: #334155;
    color: #cbd5e1;
  }

  .student-avatar {
    background-color: #475569;
    color: #e2e8f0;
  }

  .student-name {
    color: #e2e8f0;
  }

  .student-nickname {
    color: #94a3b8;
  }
}

@media (max-width: 1024px) {
  .teacher-calendar-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .placeholder {
    width: 100%;
    margin-top: 20px;
  }
  
  
}

@media (max-width: 768px) {

  .classes-table th,
  .classes-table td {
    padding: 8px;
    font-size: 0.875rem;
  }

  .page-button {
    padding: 5px;
    font-size: 0.75rem;
  }

  .attendance-table th,
  .attendance-table td {
    padding: 5px;
  }

  .rating-section,
  .rating-row {
    flex-direction: column;
  }

  .rating-comment textarea {
    width: 100%;
  }

  .classes-table-container {
    margin: 0px 0px 80px 0px !important;
    flex-direction: column;
    display: flex;
    width: 94%;
  }

}

@media (max-width:480px) {
  .classes-table-container {
    padding: 0 15px;
    overflow-y: auto;
  }
  
  .pagination-container {
    justify-content: center;
  }

  .rating-comment textarea {
    width: 100%;
  }

  .classes-table {
    display: none;
  }

  

  .all-class-card-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .all-class-card {
    background-color:#fff;
    border-radius: 15px;
    margin: 10px;
    padding: 10px;
    width: 94%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .all-class-card-header {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .all-class-card-body {
    display: flex;
    flex-direction: column;
  }

  .class-info {
    margin-bottom: 5px;
  }

  .class-info label {
    font-weight: bold;
  }

  .class-info select,
  .class-info button {
    margin-top: 5px;
  }

  .save-button,
  .edit-button {
    display: none;
  }
}

/* StarRating.css */

.star-rating {
  display: flex;
  direction: row;
  margin: auto;
  padding: 20px;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

input[type="radio"] {
  display: none;
}

.submitted-feedback p{
  font-size: 10px;
 color :#666;
 font-style: italic;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  position: relative;
  min-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: #999;
}

.modal-close-icon:hover {
  color: #666;
}

.fit-content-button {
  background: #1890ff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  width: 100%;
}

.fit-content-button:disabled {
  background: #d9d9d9;
  cursor: not-allowed;
}

.fit-content-button:not(:disabled):hover {
  background: #40a9ff;
}

select, .non-resizable-textarea {
  width: 100%;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.non-resizable-textarea {
  min-height: 80px;
  resize: none;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
}

.details-table tr {
  border-bottom: 1px solid #f0f0f0;
}

.details-table td {
  padding: 12px 8px;
  vertical-align: middle;
}

.label-cell {
  width: 120px;
  font-weight: 600;
  color: #1f2937;
}

.content-cell {
  color: #374151;
}

.edit-cell {
  width: 120px;
  text-align: right;
}

.edit-actions {
  display: flex;
  gap: 8px;
}

.edit-button {
  color: #fff;
}

.edit-button:hover {
  background: #e6f7ff;
}


/* Video Meeting Link Styles */
.video-meeting-link {
  margin: 15px 0;
}

.video-meeting-input {
  display: flex;
  gap: 10px;
  align-items: center;
}

.video-meeting-input input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
}

.video-meeting-active {
  display: flex;
  gap: 10px;
  align-items: center;
}


.delete-video-button {
  padding: 8px;
  background-color: #ef4444;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

/* PDF Section Styles */
.pdf-section {
  margin-top: 20px;
  border-top: 1px solid #e2e8f0;
  padding-top: 20px;
}

.pdf-upload-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  gap: 10px; /* Adds space between file input and button */
}

.pdf-upload-input {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between file input elements */
}

.pdf-upload-button-container {
  display: flex;
  justify-content: flex-start; /* Aligns button to the left */
}

.file-input-label {
  flex: 1;
  width: 80%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: white;
  border: 1px dashed #cbd5e1;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.2s;
}

.file-input-label:hover {
  border-color: #2563eb;
}

.file-input {
  display: none;
}

.upload-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.upload-button:disabled {
  background-color: #94a3b8;
  cursor: not-allowed;
}

.files-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.file-card {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: transform 0.2s;
}

.file-card:hover {
  transform: translateY(-2px);
}

.file-icon {
  font-size: 1.5rem;
  color: #ef4444;
}

.file-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.file-name {
  font-weight: 500;
  color: #334155;
}

.view-submissions-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: #1e3a8a;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
}

.view-submissions-button:hover {
  background-color: #f1f5f9;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* CSS to be added to your Classes.css file */

/* Submission Dialog Styling - Matches attendance modal */
.submission-dialog-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(251, 251, 251, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.submission-dialog {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 1000px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.submission-dialog-header {
  background-color: #f0f2f5;
  padding: 15px 20px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submission-dialog-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #95101e;
}

.submission-dialog-header .header-content {
  flex: 1;
}

.submission-dialog-header .class-details {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 8px;
  font-size: 0.9rem;
  color: #666;
}

.submission-dialog-header .class-details .icon {
  margin-right: 5px;
  color: #95101e;
}

.submission-dialog-header .header-actions {
  display: flex;
  gap: 10px;
}

.submission-dialog-header .close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.submission-dialog-header .close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #666;
}

.submission-dialog-header .download-button {
  background-color: #95101e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.submission-dialog-header .download-button:hover {
  background-color: #096dd9;
}

.submission-dialog-content {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(90vh - 80px);
}

/* Table Styling */
.submitted-files-table {
  width: 100%;
  overflow-x: auto;
}

.submitted-files-table table {
  width: 100%;
  border-collapse: collapse;
}

.submitted-files-table th {
  background-color: #f0f2f5;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e8e8e8;
  font-weight: 600;
  color: #333;
}

.submitted-files-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #e8e8e8;
}

.submitted-files-table tr:hover {
  background-color: #f5f5f5;
}

.file-item {
  display: flex;
  align-items: center;
  gap: 8px;
  
}

.file-icon {
  color: #f56c6c;
  font-size: 1.2rem;
}

.download-file-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #1890ff;
  background-color: transparent;
  border: 1px solid #1890ff;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.9em;
  cursor: pointer;
  text-decoration: none;
}

.download-file-button:hover {
  background-color: #e6f7ff;
}

.no-homework-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  color: #999;
  text-align: center;
}

.no-files-icon {
  font-size: 3rem;
  margin-bottom: 15px;
  opacity: 0.4;
}

/* View Submissions Button Styling */
.view-submissions-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #52c41a;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.view-submissions-button:hover {
  background-color: #389e0d;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #e2e8f0;
}

.modal-header h4 {
  margin: 0;
  color: #1e3a8a;
  font-size: 1.25rem;
  font-weight: 600;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #64748b;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s;
}

.close-button:hover {
  background-color: #f1f5f9;
  color: #334155;
}

.submitted-files-list {
  max-height: calc(90vh - 100px);
  overflow-y: auto;
  padding: 8px 0;
}

.submitted-file-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  border-bottom: 1px solid #e2e8f0;
}

.submitted-file-row:last-child {
  border-bottom: none;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 24px;
  flex: 1;
}

.student-name {
  font-weight: 500;
  color: #334155;
  min-width: 120px;
}

.file-date {
  color: #64748b;
  font-size: 0.9rem;
}


.download-icon {
  font-size: 1.1rem;
}

.no-submissions {
  padding: 24px;
  text-align: center;
  color: #64748b;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .view-submissions-button {
    background-color: #1e293b;
    border-color: #475569;
    color: #e2e8f0;
  }

  .view-submissions-button:hover {
    background-color: #334155;
  }

  .modal-content {
    background: #1e293b;
  }

  .modal-header {
    border-color: #475569;
  }

  .modal-header h4 {
    color: #e2e8f0;
  }

  .close-button {
    color: #94a3b8;
  }

  .close-button:hover {
    background-color: #334155;
    color: #e2e8f0;
  }

  .submitted-file-row {
    border-color: #475569;
  }

  .student-name {
    color: #e2e8f0;
  }

  .file-date {
    color: #94a3b8;
  }

  .download-link {
    color: #60a5fa;
  }

  .download-link:hover {
    background-color: #334155;
  }

  .no-submissions {
    color: #94a3b8;
  }
}

.submitted-homework-modal {
  padding: 24px;
}

.submitted-homework-modal h4 {
  margin: 0 0 24px 0;
  font-size: 24px;
  font-weight: 600;
  color: #dedede;
}

.homework-files {
  margin-top: 20px;
  margin-bottom: 20px;
    padding: 20px;
  background: white;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  overflow: hidden;
}

.homework-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  padding: 16px 20px;
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  color: #1e3a8a;
  font-size: 16px;
  font-weight: 600;
}


.submitted-files-table td {

  border-bottom: 1px solid #e2e8f0;
  color: #334155;
}


.download-icon {
  color: #2563eb;
}

.submitted-files-table tr:nth-child(even) {
  background-color: #f8fafc;
}

.no-homework-message {
  text-align: center;
  padding: 48px 0;
  color: #64748b;
  font-size: 18px;
}

.upload-date {
  font-size: 12px;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .submitted-homework-modal h4 {
    color: #e2e8f0;
  }

  .submitted-files-table {
    background: #1e293b;
  }

  .submitted-files-table td {
    border-color: #475569;
    color: #e2e8f0;
  }

  .submitted-files-table tr:nth-child(even) {
    background-color: #273548;
  }

  .download-icon {
    color: #60a5fa;
  }
}
/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .file-input-label,
  .file-card,
  .submitted-file-card {
    background-color: #1e293b;
    border-color: #475569;
  }

  .file-name {
    color: #e2e8f0;
  }

  .student-name {
    color: #e2e8f0;
  }
}

.delete-video-button {
  padding: 5px 10px !important;
  font-size: 14px !important;
  margin-left: 10px !important;
  background-color: #be6464 !important;
  color: white !important;
}

/*CurrentClasses.js*/
.cc-class-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 60px 10px 60px 70px;
}

.cc-class-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 98%;
  padding: 15px 20px;
  background-color: #2A4860;
  overflow-x: auto; 
  white-space: nowrap; 
}

.cc-class-header h1 {
  font-size: 28px;
  color: #FF6B6B;
  margin-bottom: 10px;
}

.cc-search-container {
  margin: 20px 0px 10px 20px;
  width: 100%;
  align-items: center;
}

.cc-search-input {
  width: 50%;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.cc-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 20px;
  width: 100%;
}

.cc-item {
  display: flex;
  margin: 0px 20px 0px 20px;
  align-items: center;
  padding: 20px;
  border-radius: 15px;
  background: #fff;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.cc-item:hover {
  transform: scale(1.02);
}

.cc-icon {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  font-size: 24px;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.cc-icon-0 { background: #e53935; }
.cc-icon-1 { background: #e53935; }
.cc-icon-2 { background: #e53935; }

.cc-content {
  flex: 1;
}

.cc-title {
  font-size: 1.5rem;
  margin: 0;
}

.cc-subtitle {
  color: #666;
  font-size: 0.9rem;
  margin: 5px 0;
}

.cc-schedule-button {
  background-color: #95101e;
  color: white;
  gap: 5px;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cc-schedule-button:hover {
  background-color: #95101e;
}

.cc-schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.cc-schedule-table th, .cc-schedule-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

@media (max-width: 768px) {
  .cc-class-container {
    align-items: center;
    margin: 20px 10px 80px 10px; 
  }

  .cc-class-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 95%;
    margin: 40px 20px 20px 20px;
    background-color: #2A4860;
    border-radius: 0px 0px 35px 35px;
    overflow-x: auto; 
    white-space: nowrap; 
  }

  .cc-search-input {
    width: 80%;
    align-items: center;
  }

  .cc-list {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
    gap: 10px;
  }

  .cc-item {
    padding: 15px; /* Reduce padding */
    flex-direction: column; /* Stack content vertically */
    align-items: flex-start; /* Align items to start */
  }

  .cc-icon {
    width: 40px; /* Smaller icon size */
    height: 40px;
    margin-right: 10px;
  }

  .cc-title {
    font-size: 1.2rem;
  }

  .cc-subtitle {
    font-size: 0.8rem;
  }

  
}
