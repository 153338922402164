/* Modern Header Styles */
.modern-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 60px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.header-container {
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

/* Logo Section */
.logo-section {
  display: flex;
  align-items: center;
}

.modern-logo {
  height: 50px;
  object-fit: contain;
}

/* Header Actions */
.header-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

/* User Profile */
.user-profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 6px;
  transition: background-color 0.2s;
  color: #333;
}

.user-profile:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.username-text {
  margin-left: 8px;
  font-size: 15px;
}

/* Icons */
.header-icon {
  font-size: 16px;
  color: #555;
}

.user-icon {
  color: #95101e;
}

/* Buttons */
.modern-button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0 12px;
}

.modern-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.button-text {
  margin-left: 8px;
  font-size: 14px;
  display: inline-block;
}

/* Chat Button */
.chat-button {
  background-color: #f8f8f8;
  position: relative;
}

.chat-button:hover {
  background-color: #e8e8e8;
}

.notification-indicator {
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #95101e;
  color: white;
  font-size: 11px;
  font-weight: bold;
  height: 18px;
  min-width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 0 4px;
}

/* Doodle Button */
.doodle-button {
  background-color: #f8f8f8;
}

.doodle-button:hover {
  background-color: #e8e8e8;
}

.doodle-button .header-icon {
  color: #deba1d;
}

/* Sign Out Button */
.signout-button {
  background-color: #f8f8f8;
  color: #95101e;
  font-weight: 500;
}

.signout-button:hover {
  background-color: #ffeaed;
}

.signout-button .header-icon {
  color: #95101e;
}

/* Language Switcher */
.language-control {
  margin-left: 8px;
}
.profile-popup {
  opacity: 0;
  transform: translateY(-10px);
  transition: all 1.0s ease;
}

.profile-popup.show {
  opacity: 1;
  transform: translateY(0);
}



/* Password Change Form */
.change-password-form {
  margin-top: 24px;
  text-align: left;
}

.change-password-form label {
  display: block;
  margin-bottom: 16px;
  font-size: 14px;
  color: #555;
}

.change-password-form input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 6px;
  font-size: 14px;
  transition: border-color 0.2s;
}

.change-password-form input:focus {
  border-color: #95101e;
  outline: none;
}

.change-password-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.change-password-btn {
  background-color: #95101e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.change-password-btn:hover {
  background-color: #7a0e19;
}

.cancel-btn {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.cancel-btn:hover {
  background-color: #e0e0e0;
}

.close-button {
  margin-top: 16px;
  background-color: transparent;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.close-button:hover {
  background-color: #f8f8f8;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .header-container {
    padding: 0 16px;
  }
  
  .username-text {
    display: none;
  }
  
  .button-text {
    display: none;
  }
  
  .modern-button {
    width: 36px;
    min-width: 36px;
    padding: 0;
  }
  
  .header-actions {
    gap: 8px;
  }
}

@media (max-width: 480px) {
  .modern-header {
    height: 60px;
  }
  
  .modern-logo {
    height: 40px;
  }
  
  .profile-popup {
    width: calc(100% - 32px);
    right: 16px;
  }
}